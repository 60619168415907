class Card {
    constructor(element) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError("Invalid element");
        }

        this.element = element;
        this.content = this.element.querySelector(".card__content");
        this.copy = this.element.querySelector(".card__copy");
        this.title = this.element.querySelector(".card__title");

        this.setHeights();
        window.addEventListener("resize", this.setHeights, { passive: true });
    }

    setHeights() {
        if(this.copy){
            const height = this.copy.offsetHeight;
            this.element.style.setProperty("--height", `${height}px`);
        }

        setTimeout(() => this.element.classList.add("initialized"), 1);
    }
}

export default Card;
