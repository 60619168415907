import Swiper, {Navigation} from 'swiper';

class TestimonialSlider {
	constructor(element) {
		if (!(element instanceof HTMLElement)) {
			throw new TypeError("Invalid element");
		}

		this.element = element;
		this.swiper = null;
		this.initSwiper();
	}

	initSwiper() {
		console.log({
			next: this.element.querySelector('.swiper-button-next'),
			prev: this.element.querySelector('.swiper-button-prev'),
		})
		this.swiper = new Swiper(this.element, {
			modules: [Navigation],
			allowTouchMove: true,
			allowClick: true,
			spaceBetween: 50,
			navigation: {
				nextEl: this.element.querySelector('.swiper-button-next'),
				prevEl: this.element.querySelector('.swiper-button-prev'),
			},
		});
	}
}

export default TestimonialSlider;
