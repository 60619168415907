import Swiper, {Navigation} from 'swiper';
import Card from "./Card";

class CardSlider {
	constructor(element) {
		if (!(element instanceof HTMLElement)) {
			throw new TypeError("Invalid element");
		}

		this.element = element;
		this.swiperEl = this.element.querySelector(".swiper");
		this.swiper = null;
		this.initSwiper();
	}

	initSwiper() {
		this.swiper = new Swiper(this.swiperEl, {
			allowTouchMove: true,
			modules: [Navigation],
			allowClick: true,
			slidesPerView: "auto",
			spaceBetween: 20,
			navigation: {
				nextEl: this.element.querySelector('.swiper-button-next'),
				prevEl: this.element.querySelector('.swiper-button-prev'),
			},
			on: {
				init: function (swiper) {
					swiper.slides.forEach(el => {
						const card = el.querySelector(".card");

						if (card !== null) {
							el.card = new Card(card);
						}
					});
				}
			}
		});
	}
}

export default CardSlider;
