import AOS from 'aos';
import { isCrawlerUserAgent } from 'is-web-crawler';

export default {
    init() {
        if (isCrawlerUserAgent()) {
            const aosElements = document.querySelectorAll('[data-aos]');

            Array.from(aosElements)
                .forEach(el => {
                    if (el.dataset.aos) {
                        el.dataset.noAos = el.dataset.aos;
                        el.dataset.aos = "false";
                    }
                });
        } else {
            AOS.init({
                disable: 'mobile',
                duration: 1000,
                once: true
            });
        }
    },
}
