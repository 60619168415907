/* globals jQuery, wordpress_globals */
import "../sass/theme.scss";

import $ from 'jquery';
import svgxhr from 'webpack-svgstore-plugin/src/helpers/svgxhr';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import MicroModal from "micromodal";

import Card from "./modules/Card";
import CardSlider from "./modules/CardSlider";
import TeamMember from "./modules/TeamMember";
import StatSlider from "./modules/StatSlider";
import TestimonialSlider from "./modules/TestimonialSlider";
import ParallaxSwiper from "./modules/ParallaxSwiper";
import Animations from "./modules/Animations";

// Remove NinjaForms tags like <nf-field>
(function ($) {
	$(window).load(function () {
		setTimeout(() => {
			$('.nf-form-cont').find("nf-field").contents().unwrap();
			$('.nf-form-cont').find("nf-fields-wrap").contents().unwrap();
			$('.nf-form-cont').find("nf-section").contents().unwrap();
			$('.nf-form-cont').find("nf-errors").contents().unwrap();
			$('.nf-form-cont').addClass("fields-fixed");
		}, 100);
	});
})(jQuery);


window.namespace = {
	init: function() {
		Animations.init();

		MicroModal.init({
			onShow: () => {
				window.document.documentElement.classList.add("modal-open");
			},
			onClose: () => {
				window.document.documentElement.classList.remove("modal-open");
			},
			openClass: 'is-open',
			disableScroll: true,
			disableFocus: false,
			awaitOpenAnimation: false,
			awaitCloseAnimation: false,
		});

		this.helpers.initSvg();

		if (document.querySelector('.card') !== null) {
			document.querySelectorAll('.card').forEach(el => new Card(el));
		}

		if (document.querySelector('.card-slider') !== null) {
			document.querySelectorAll('.card-slider').forEach(el => new CardSlider(el));
		}

		if (document.querySelector('.stats-slider') !== null) {
			document.querySelectorAll('.stats-slider').forEach(el => new StatSlider(el));
		}

		if (document.querySelector('.card--team-member') !== null) {
			document.querySelectorAll('.card--team-member').forEach(el => new TeamMember(el));
		}

		if (document.querySelector('.testimonial-slider') !== null) {
			document.querySelectorAll('.testimonial-slider').forEach(el => new TestimonialSlider(el));
		}

		if (document.querySelector('.parallax-gallery') !== null) {
			document.querySelectorAll('.parallax-gallery').forEach(el => new ParallaxSwiper(el));
		}

		if (document.querySelectorAll(".menu-toggle") !== null) {
			document.querySelectorAll(".menu-toggle").forEach(el => {
				el.addEventListener("click", (event) => {
					event.preventDefault();
					window.document.documentElement.classList.toggle("site-menu-open");
				});
			});
		}

		//Allow user to interrupt auto-scrolling
		$(document).bind('scroll mousedown wheel keyup', function(e) {
			if(e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
				$(document.scrollingElement).stop();
			}
		});
	},
	helpers: {
		requireAll: function(r) {
			r.keys().forEach(r);
		},
		initSvg: function (){
			svgxhr({
				filename: './wp-content/themes/class/dist/sprite.svg', //URL to fetch
			});
		}
	}

	// Prefer creating new modules when appropriate, rather than adding functions here
	// If you need something to be globally accessible, you can assign an imported module to this namespace
	// I.e import Contact from './contact';
	// window.namespace.Contact = Contact;
};

window.namespace.init();


if(typeof NodeList.prototype.forEach !== 'function'){
	NodeList.prototype.forEach = Array.prototype.forEach;
}

// SVG Sprite Loader
window.namespace.helpers.requireAll(require.context('../images/icons/', true, /\.svg$/));
