/** Images Gallery Section */
import simpleParallax from 'simple-parallax-js';

class ParallaxSwiper {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.element = element;
    this.rows = this.element.querySelectorAll(".gallery-row");
    this.rowObjects = [];
    this.parallax = null;
    this.initRows();
  }
  initRows() {
    if(typeof this.rows !== "undefined" && this.rows.length){
      this.rows.forEach((el, idx) => {
        const row = new simpleParallax(el.querySelector(".swiper-wrapper"), {
          delay: 0.4,
          orientation: (idx % 2 === 0) ? "left" : "right",
          scale: 1.9,
          overflow: true,
        });

        this.rowObjects.push(row);
      });
    }
  }
}

export default ParallaxSwiper;
