class TeamMember {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.element = element;
    this.modalId = this.element.dataset.micromodalTrigger ?? null;
    this.modal = document.querySelector(`#${this.modalId}`);
    this.modalContent = this.modal.querySelector(".team-member-details");
    this.modalImg = this.modal.querySelector(".team-member-modal__image img");

    if (this.modalId === null || this.modal === null) {
      throw new TypeError("Invalid data-modal-id attribute");
    }

    this.initEvents();
  }

  initEvents() {
    this.element.addEventListener("click", this.openModal.bind(this));
  }

  openModal(event) {
    event.preventDefault();
    const JSONString = event.currentTarget.dataset.object ?? "null";
    const JSONObject = JSON.parse(JSONString);

    const email = (typeof JSONObject.email !== "undefined") ? `
      <li>
        <a href="mailto:${JSONObject.email}" class="animated-link">
          <div class="animated-link__circle">
            <div class="animated-link__fill"></div>
            <svg class="animated-link__icon"><use xlink:href="#icon--arrow" /></svg>
          </div>
          <span class="animated-link__title">
            ${JSONObject.email}
          </span>
        </a>
      </li>
    ` : "";

    this.modalContent.innerHTML = `
      <h3 class="team-member-details__name">${JSONObject.title ?? ""}</h3>
      <span class="team-member-details__job">${JSONObject.job_title ?? ""}</span>
      <div class="team-member-details__bio">
        ${JSONObject.content ?? ""}
      </div>
    `;
    // <ul class="team-member-details__contact">
    //   ${email ?? ""}
    // </ul>

    if (typeof JSONObject.imageURL !== null) {
      this.modalImg.src = JSONObject.imageURL;
    }
  }
}

export default TeamMember;
